import './App.css';
import Particles from "react-tsparticles";
import {loadFull} from "tsparticles";
import Buttonner from "./Buttonner";

function App() {
    const particlesInit = async (main) => {
        console.log(main);
        await loadFull(main);
    }
    const particlesLoaded = (container) => {
        console.log(container);
    }

  return (
    <div className="App">
        <Particles
            id="tsparticles"
            init={particlesInit}
            loaded={particlesLoaded}
            options={{
                background: {
                    color: {
                        value: "#0d47a1",
                    },
                },
                fpsLimit: 120,
                interactivity: {
                    events: {
                        resize: true,
                    },
                    modes: {

                    },
                },
                particles: {
                    color: {
                        value: "#ffffff",
                    },
                    links: {
                        color: "#ffffff",
                        distance: 150,
                        enable: false,
                        opacity: 0.5,
                        width: 1,
                    },
                    collisions: {
                        enable: false,
                    },
                    move: {
                        direction: "top",
                        enable: true,
                        outModes: {
                            default: "out",
                        },
                        random: false,
                        speed: 1,
                        straight: false,
                    },
                    number: {
                        density: {
                            enable: false
                        },
                        value: 160,
                    },
                    opacity: {
                        value: 0.5,
                    },
                    shape: {
                        type: "circle",
                    },
                    size: {
                        value: { min: 1, max: 5 },
                    },
                },
                detectRetina: true,
            }}
        >
        </Particles>
        <div class={"body"}>
            <h1>Twitter for EIon Musk</h1>
            <Buttonner />
        </div>
    </div>
  );
}

export default App;
