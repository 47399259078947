import Cookies from "universal-cookie";
import _Cookies from "js-cookie";

export default function Login(){
    const cookies = new Cookies();
    const search = window.location.search;
    const params = new URLSearchParams(search);
    fetch("https://apiemusk.krbk.dev/cb?oauth_token=" + params.get("oauth_token")+"&oauth_verifier="+params.get("oauth_verifier"), {headers: {Authorization: _Cookies.get("token")}}).then(function(response){
        response.text().then(r => {
            console.log(r);
            console.log(params.get("oauth_token"), params.get("oauth_verifier"), _Cookies.get("token"));
            cookies.set("token", r, {path: "/"});
            localStorage.setItem("token", r);
        });
    });

    return (
        <div>

        </div>
    )
}
