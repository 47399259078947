import "./App.css";
import React, { useState, useRef } from "react";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
const MySwal = withReactContent(Swal);
function Tweet(){
    const [content, setContent] = useState("");
    const divRef = useRef();
    const handleChange = (event) => {
        if (event.target.value.length > 280) {
            return;
        } else {
            setContent(event.target.value);
            divRef.current.style = `width: ${(event.target.value.length * 100) / 280}%; background-color: ${(event.target.value.length * 100) / 100 > 200 ? "red" : "green"}`;
        }
    }

    const tweet = () => {
        if(content.length > 0){
            fetch("https://apiemusk.krbk.dev/tweet", {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                    "Authorization": localStorage.getItem("token")
                },
                body: JSON.stringify({
                    tweet: content
                })
            }).then(r=>r.json()).then(j=>{
                if(j.success){
                    MySwal.fire({
                        title: "Tweeted!",
                        icon: "success",
                        html: <span><a href={j.link} target="_blank">Ton tweet</a> a bien été publié !</span>
                    });
                    setContent("");
                } else {
                    MySwal.fire({
                        title: "Erreur !",
                        icon: "error",
                        html: "Une erreur est survenue."
                    });
                }
            }).catch(e=>{
                MySwal.fire({
                    title: "Erreur !",
                    icon: "error",
                    html: e.message
                });
            })
        }
    }

    console.log(content);
    return (
        <div className={"tweet"}>
            <textarea
                value={content}
                className={'tweet-content'}
                onChange={handleChange}
                placeholder={"Quoi de neuf ?"}
            />
            <div className={"ouai"}>
                <div className={'tweet-length'} ref={divRef}></div>
                <span>{content.length}/280</span>
            </div>
            <button className={"login"} onClick={tweet}>Tweeter</button>
        </div>
    )
}

export default Tweet;
