import "./App.css";
import React, { useState } from "react";
import Twemoji from "react-twemoji";
import Tweet from "./Tweet.js"

function Buttonner() {
    const [isLoggedIn, setIsLoggedIn] = useState(false);
    const [user, setUser] = useState({});
    const [hasLoaded, setHasLoaded] = useState(false);

    if(!hasLoaded) {
        fetch("https://apiemusk.krbk.dev/data", {credentials: "include"}).then(res => res.json()).then(res => {
            setHasLoaded(true);
            console.log("setHasLoaded");
            if(res.user){
                setIsLoggedIn(true);
                setUser(res);
            }
        }).catch(err => {
            console.log(err);
            setHasLoaded(true);
        })
    }

    const goToLogin = () => {
        console.log("go to login");
        const win = window.open("https://apiemusk.krbk.dev/login","newWindow", "width=500,height=500");
        let i = setInterval(()=>{
            if(win.closed !== false) {
                clearInterval(i);
                fetch("https://apiemusk.krbk.dev/data", {credentials: "include"}).then(res => res.json()).then(res => {
                    setIsLoggedIn(true);
                    setUser(res);
                }).catch(err => {
                    console.log(err);
                })
            }
        })
    }

    return (
        <div>
            {hasLoaded ? (
                <div>
                    {isLoggedIn ? (
                        <div>
                            <div className={"userdetails"}>
                                <img src={user.avatar} className={"avatar"} alt="avatar" />
                                <Twemoji>
                                    <p>{user.fullName}</p>
                                </Twemoji>
                            </div>
                            <Tweet />
                        </div>
                    ) : (
                        <button onClick={goToLogin} class="login">Connexion avec Twitter</button>
                    )}
                </div>
            ) : (
                <div>
                    Chargement...
                </div>
            )}
        </div>
    )
}

export default Buttonner;
